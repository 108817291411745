@use 'devices';

.bif-templates-category-list {
    display: grid;
    gap: var(--cinemo-sizes-gap-xxs);
    padding: var(--cinemo-sizes-gap-s) var(--cinemo-sizes-keyline-m);

    &--no-paddings {
        padding: 0;
    }

    &--device,
    &--playlist {
        @include devices.tablet-landscape-up {
            grid-template-columns: 1fr 1fr;
        }
    }

    &--group,
    &--queue {
        @include devices.tablet-portrait {
            grid-template-columns: 1fr 1fr;
        }
        @include devices.tablet-landscape-up {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }
}
