.bif-components-combobox__popover__content {
    $name: &;

    &-exit {
        opacity: 1;
        transform: scale(1);
    }

    &-exit-active {
        opacity: 0;
        transition-timing-function: ease-out;
        transform: scaleY(0.75);
        transition:
            opacity 100ms,
            transform 100ms;

        transform-origin: top;
        &[data-side='top'] {
            transform-origin: bottom;
        }
    }

    &-exit-done,
    &--not-initialized {
        & > :first-child {
            visibility: hidden;
            height: 0;
            pointer-events: none;
            border: none;
        }
    }

    &-enter {
        opacity: 0;
        transform: scaleY(0.75);
    }

    &-enter-active {
        opacity: 1;
        transition-timing-function: ease-out;
        transform: scale(1);
        transition:
            opacity 200ms,
            transform 200ms;

        transform-origin: top;
        &[data-side='top'] {
            transform-origin: bottom;
        }
    }
}
