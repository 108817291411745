@use 'typography';
@use 'events';

.bif-components-list {
    height: 40vh;

    .bif-components-scrollable-area__viewport {
        width: 100%;
        max-width: 100%;
        overflow-x: hidden !important;

        & > div {
            display: block !important;
            & > * {
                margin: var(--cinemo-sizes-space-xxs) 0;
            }
        }
    }

    .bif-components-separator {
        width: 96%;
    }

    &__item {
        $name: &;
        position: relative;
        overflow: hidden;
        display: flex;
        border-radius: 0.6rem;
        transition: 0.3s border-radius;

        &--prev-checked {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }

        &--next-checked {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }

        &__input {
            display: grid;
            place-items: center;
            position: absolute;
            left: 1.4rem;
            top: 1.4rem;
            border: 1.5px solid var(--cinemo-colors-black-600);
            width: 2rem;
            height: 2rem;
            accent-color: var(--cinemo-colors-black-100);
            border-radius: 50%;
            appearance: none;
            -webkit-appearance: none;
            cursor: pointer;

            &:checked {
                &[type='checkbox'] {
                    border: none;
                    background-color: var(--cinemo-colors-black-100);

                    &::after {
                        content: url('@assets/svg/Icon.Check.svg');
                    }
                }

                &[type='radio'] {
                    border-color: var(--cinemo-colors-black-100);
                    &::after {
                        display: block;
                        content: '';
                        width: 1.2rem;
                        height: 1.2rem;
                        border-radius: 50%;
                        background-color: var(--cinemo-colors-black-100);
                    }
                }
            }

            &:disabled {
                border-color: var(--cinemo-colors-white-transparent-10);
                cursor: default;
            }

            &:not(:disabled) {
                @include events.hover-focus {
                    & + #{$name}__label {
                        background-color: var(--cinemo-colors-white-transparent-05);
                    }
                }
            }
        }

        &__input:checked + &__label {
            background-color: var(--cinemo-colors-white-transparent-10);
        }

        &__input:checked:not(:disabled) {
            @include events.hover-focus {
                & + #{$name}__label {
                    background-color: var(--cinemo-colors-white-transparent-20);
                }
            }
        }

        &__input:disabled + &__label {
            color: var(--cinemo-colors-white-transparent-30);
            background-color: transparent;
            cursor: default;
        }

        &__label {
            width: 100%;
            min-height: 4.8rem;
            padding: 0 1.2rem 0 4.6rem;
            display: grid;
            grid-template-columns: 1fr max-content;
            grid-template-rows: 4.8rem 1fr;
            align-items: center;
            background-color: transparent;
            transition: 0.3s background-color;
            cursor: pointer;
            @include typography.body-primary;
            color: var(--cinemo-colors-black-100);
            text-align: left;
            user-select: none;

            &__text {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow-x: hidden;
            }

            @include events.hover-focus {
                background-color: var(--cinemo-colors-white-transparent-05);
            }
        }
    }

    &__nested__trigger {
        height: 100%;
        padding: 0;
        padding-right: 0.8rem;
        margin-right: -1.2rem;
        background: none;
        border: none;
        display: flex;
        align-items: center;
        gap: 0.8rem;
        cursor: pointer;

        @include typography.caption-primary;
        color: #ffffff80;

        svg {
            width: 1.6rem;
            height: 1.6rem;
            transform: rotate(0deg);
            transition: transform 0.3s;
        }

        &--open {
            svg {
                transform: rotate(-180deg);
            }
        }
    }

    &__nested__content {
        pointer-events: none;
        grid-column: span 2;
        margin-right: -1.2rem;

        .bif-components-list__item__input {
            display: none;
        }

        .bif-components-list__item__label {
            padding-left: 0;
        }
    }

    &__new-item-button {
        @include typography.button;
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: row;
        gap: var(--cinemo-sizes-space-xl);
        padding: var(--cinemo-sizes-space-xl);
        background-color: transparent;
        border: var(--cinemo-sizes-border-s) dashed var(--cinemo-colors-black-700);
        border-radius: var(--cinemo-sizes-radius-m);
        color: var(--cinemo-colors-black-100);

        @include events.hover {
            cursor: pointer;
            border-color: var(--cinemo-colors-black-500);
            background-color: var(--cinemo-colors-black-700);
        }

        &:focus {
            outline: none;
        }

        &--disabled {
            pointer-events: none;
            border-color: var(--cinemo-colors-white-transparent-30);
            color: var(--cinemo-colors-white-transparent-30);
        }

        &__icon {
            width: 2rem;
            height: 2rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background-color: var(--cinemo-colors-white-transparent-10);

            svg {
                width: 1.2rem;
                height: 1.2rem;
                color: var(--cinemo-colors-black-100);
            }
        }

        &__text {
            flex: 1;
            text-align: start;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow-x: hidden;
        }
    }
}
