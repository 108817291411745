@use 'typography';

.bif-components-badge {
    display: inline-grid;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding: 0 var(--cinemo-sizes-space-xs);
    @include typography.caption-secondary;

    &--shape-circle {
        height: 3.2rem;
        min-width: 2.4rem;
        border-radius: 3rem;
    }

    &--shape-pill {
        height: 1.6rem;
        border-radius: 2.4rem;
        gap: var(--cinemo-sizes-space-m);
    }

    &--type-light {
        background-color: var(--cinemo-colors-white-transparent-10);
        color: var(--cinemo-colors-white-transparent-70);
    }

    &--type-dark {
        background-color: var(--cinemo-colors-white-transparent-30);
        color: var(--cinemo-colors-black-transparent-70);
    }

    &--type-deep-dark {
        background-color: var(--cinemo-colors-black-900);
        color: var(--cinemo-colors-black-100);
    }

    &--type-dimmed-light {
        background-color: var(--cinemo-colors-white-transparent-20);
        color: var(--cinemo-colors-black-100);
    }
}
