.bif-components-scrollable-area {
    display: grid;
    width: inherit;
    height: inherit;
    max-height: inherit;
    overflow: hidden;

    &__viewport {
        width: 100%;
        height: 100%;
        max-height: inherit;
    }

    &__scrollbar {
        display: grid;
        user-select: none;
        touch-action: none;
        background-color: var(--cinemo-colors-black-transparent-50);
        transition: background-color 0.1s ease-out;

        &:hover {
            background-color: var(--cinemo-colors-black-transparent-30);
        }

        &[data-orientation='vertical'] {
            grid-auto-flow: row;
            width: var(--cinemo-sizes-space-xxxs);
        }

        &[data-orientation='horizontal'] {
            grid-auto-flow: column;
            height: var(--cinemo-sizes-space-xxxs);
        }

        &__thumb {
            position: relative;
            background-color: var(--cinemo-colors-white-transparent-50);
            border-radius: var(--cinemo-sizes-space-xxxs);

            &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                height: 100%;
            }
        }
    }

    &__corner {
        background-color: var(--cinemo-colors-black-transparent-50);
    }
}
