@use 'typography';
@use 'devices';
@use 'events';

.bif-components-date-picker-trigger {
    display: flex;
    padding: 1.2rem;
    margin: -1.2rem;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 50%;

    svg {
        color: var(--cinemo-colors-black-100);
    }

    @include events.hover-focus {
        background-color: var(--cinemo-colors-white-transparent-10);
    }
}

.bif-components-date-picker-overlay {
    position: fixed;
    inset: 0;
    background: var(--cinemo-colors-black-transparent-80);
    backdrop-filter: blur(1rem);
    z-index: 3;

    @include devices.tablet-portrait-up {
        display: none;
    }
}

.bif-components-date-picker-overlay + div[data-radix-popper-content-wrapper] {
    position: absolute !important;
    width: 100%;

    @include devices.tablet-portrait-up {
        position: fixed !important;
        width: 0%;
    }
}

.bif-components-date-picker {
    width: 100%;
    min-width: 31.1rem;
    box-sizing: border-box;
    display: grid;
    grid-template-rows: max-content 1fr;
    gap: var(--cinemo-sizes-space-xl);
    border-radius: var(--cinemo-sizes-radius-m);
    padding: 0;
    background: var(--cinemo-colors-black-800);
    box-shadow: 0 0 2rem 0 #0000004d;
    z-index: 4;

    &__header {
        position: relative;
        width: 100%;
        padding: var(--cinemo-sizes-space-xxl);
        padding-bottom: 0;
        box-sizing: border-box;

        &::after {
            content: '';
            position: absolute;
            display: block;
            width: 100%;
            bottom: -0.8rem;
            left: 0rem;
            border-bottom: 1px solid var(--cinemo-colors-white-transparent-20);
        }
    }

    &__days {
        padding: var(--cinemo-sizes-space-xxl);
        padding-top: 0;
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        grid-auto-rows: 3.467rem;

        &__day-of-week {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            height: 3.467rem;
            @include typography.caption-secondary;
            color: var(--cinemo-colors-black-600);

            &[title] {
                text-decoration: none;
            }
        }
    }

    &__months {
        padding: var(--cinemo-sizes-space-xxl);
        padding-top: 0;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-auto-rows: 5.2rem;
    }
}
