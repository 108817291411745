@use 'devices';
@use 'typography';

@keyframes hide {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@keyframes slide-in {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(0px);
    }
}

@keyframes swipe-out {
    from {
        transform: translateX(var(--radix-toast-swipe-end-x));
    }

    to {
        transform: translateX(100%);
    }
}

.bif-components-toast-viewport {
    display: grid;
    grid-auto-flow: row;
    row-gap: var(--cinemo-sizes-gap-xs);
    box-sizing: border-box;
    position: fixed;
    top: 0rem;
    right: 0rem;
    z-index: 6;
    width: 38rem;
    padding: 0rem;
    margin: var(--cinemo-sizes-gap-xxxl);
    list-style: none;
    outline: none;

    @include devices.phone {
        width: calc(100vw - 2 * var(--cinemo-sizes-gap-s));
        margin: var(--cinemo-sizes-gap-xxxl) var(--cinemo-sizes-gap-s);
    }
}

.bif-components-toast {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: auto 1fr auto;
    grid-template-areas: 'slot text action';
    align-items: center;
    padding: var(--cinemo-sizes-space-xxl);
    border-radius: var(--cinemo-sizes-radius-m);
    box-shadow: var(--cinemo-shadow-area);

    &[data-state='open'] {
        animation: slide-in 0.2s ease-out;
    }

    &[data-state='closed'] {
        animation: hide 0.1s ease-in;
    }

    &[data-swipe='move'] {
        transform: translateX(var(--radix-toast-swipe-move-x));
    }

    &[data-swipe='cancel'] {
        transform: translateX(0px);
        transition: transform 0.2s ease-out;
    }

    &[data-swipe='end'] {
        animation: swipe-out 0.1s ease-out;
    }

    &--severity-info,
    &--severity-warning {
        color: var(--cinemo-colors-black-900);

        .bif-components-button {
            color: var(--cinemo-colors-black-900);

            &:hover {
                color: var(--cinemo-colors-black-700);
            }
        }
    }

    &--severity-success,
    &--severity-error {
        color: var(--cinemo-colors-black-100);
    }

    &--severity-info {
        background-color: var(--cinemo-colors-black-100);
    }

    &--severity-success {
        background-color: var(--cinemo-colors-green-500);
    }

    &--severity-warning {
        background-color: var(--cinemo-colors-yellow-500);
    }

    &--severity-error {
        background-color: var(--cinemo-colors-red-500);
    }

    &__slot {
        grid-area: slot;
        display: grid;
        margin-right: var(--cinemo-sizes-gap-xs);

        svg {
            width: var(--cinemo-sizes-icon-m);
            height: var(--cinemo-sizes-icon-m);
        }
    }

    &__text {
        grid-area: text;
        min-height: 2.4rem;
        display: grid;
        align-items: center;

        @include typography.body-secondary;

        &--align-left {
            text-align: left;
        }

        &--align-center {
            text-align: center;
        }

        &--align-right {
            text-align: right;
        }
    }

    &__action {
        grid-area: action;
        margin-left: var(--cinemo-sizes-space-xxl);
    }
}
