@use 'typography';

@keyframes collapse-up {
    from {
        opacity: 1;
        max-height: var(--radix-collapsible-content-height);
    }

    to {
        opacity: 0;
        max-height: 0rem;
    }
}

@keyframes collapse-down {
    from {
        opacity: 0;
        max-height: 0rem;
    }

    to {
        opacity: 1;
        max-height: var(--radix-collapsible-content-height);
    }
}

.bif-components-collapsible-area {
    $name: &;

    display: grid;
    grid-template-columns: 1fr max-content;
    grid-template-rows: max-content max-content;
    gap: var(--cinemo-sizes-space-xl);
    width: inherit;
    height: inherit;

    &--open {
        grid-template-areas: 'title trigger-open' 'content content';

        & #{$name}__trigger--state-closed {
            display: none;
        }
    }

    &--closed {
        grid-template-areas: 'title trigger-closed' 'content content';

        & #{$name}__trigger--state-open {
            display: none;
        }
    }

    &__title {
        grid-area: title;
        display: grid;
        grid-auto-flow: column;
        justify-content: space-between;
        align-items: center;
        color: var(--cinemo-colors-black-100);
        user-select: none;

        @include typography.h4;
    }

    &__trigger {
        display: grid;
        grid-auto-flow: column;
        justify-items: center;
        align-items: center;
        color: var(--cinemo-colors-black-100);
        cursor: pointer;

        svg {
            width: var(--cinemo-sizes-icon-m);
            height: var(--cinemo-sizes-icon-m);
        }

        &--state-open {
            grid-area: trigger-open;
        }

        &--state-closed {
            grid-area: trigger-closed;
        }
    }

    &__content {
        grid-area: content;
        display: grid;
        grid-auto-flow: row;
        overflow: hidden;

        &[data-state='open'] {
            animation: collapse-down 200ms ease-out;
        }

        &[data-state='closed'] {
            animation: collapse-up 100ms ease-out;
        }
    }
}
