@use 'devices';

.bif-templates-track-page {
    display: grid;
    grid-template-rows: min-content 1fr;
    padding: 0 var(--cinemo-sizes-keyline-m);
    height: 100%;
    box-sizing: border-box;

    &__cover {
        display: grid;
        margin-bottom: var(--cinemo-sizes-gap-s);
        justify-self: center;
        &__img {
            display: grid;
            justify-self: center;
        }
    }

    &__track {
        display: grid;
        grid-template-rows: min-content 1fr;

        &__metadata {
            display: grid;
            gap: var(--cinemo-sizes-gap-s);
        }
        &__controls {
            display: flex;
            flex-direction: column;
            align-self: end;
            height: 27.2rem;

            @include devices.tablet-portrait {
                height: 28.8rem;
            }
            @include devices.tablet-landscape-up {
                height: 27.8rem;
            }
        }
    }

    @include devices.tablet-landscape-up {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: none;
        column-gap: var(--cinemo-sizes-gap-l);
        padding: var(--cinemo-sizes-gap-s) var(--cinemo-sizes-keyline-m);
        align-items: center;

        &__cover {
            margin-bottom: 0;
        }

        &__track {
            display: grid;
            align-self: center;
            width: 100%;
            max-width: var(--cinemo-sizes-cover-xl);
            height: var(--cinemo-sizes-cover-xl);
            margin: auto;
        }
    }
}
