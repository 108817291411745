@use 'typography';

.bif-components-label {
    display: inline-grid;
    justify-content: center;
    align-items: center;
    padding: var(--cinemo-sizes-radius-xxs) var(--cinemo-sizes-space-xs);
    border-radius: var(--cinemo-sizes-radius-m);
    background-color: var(--cinemo-colors-black-100);
    color: var(--cinemo-colors-black-900);

    @include typography.caption-primary;
}
