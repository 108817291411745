@use 'typography';
@use 'events';

.bif-components-date-picker-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--cinemo-sizes-space-xxl);
    padding-bottom: 0;

    &__switch {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 12rem;

        &__button {
            width: 100%;
            padding: var(--cinemo-sizes-space-xl) 0;
            margin: calc(-1 * var(--cinemo-sizes-space-xl)) 0;
            background: none;
            border: none;
            cursor: pointer;
            @include typography.h5;
            color: var(--cinemo-colors-black-100);
            text-align: center;
            outline: none;
            border-radius: 0.8rem;

            @include events.hover-focus {
                background-color: var(--cinemo-colors-white-transparent-10);
            }
        }
    }
}
