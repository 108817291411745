@use 'typography';
@use 'events';

.bif-components-checkbox {
    $name: &;

    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &--label-left {
        grid-template-areas: 'label input';
    }

    &--label-right {
        grid-template-areas: 'input label';
    }

    &--spacing-regular {
        margin-bottom: var(--cinemo-sizes-space-xxxl);
    }

    &--layout-min {
        max-width: min-content;
    }

    &--layout-max {
        width: 100%;
    }

    &--type-regular {
        &#{$name}--with-label {
            box-sizing: border-box;
            padding: var(--cinemo-sizes-space-xl);

            &:not(#{$name}--disabled) {
                border-radius: var(--cinemo-sizes-radius-m);

                @include events.hover {
                    background-color: var(--cinemo-colors-white-transparent-10);
                }
            }
        }

        &:not(:hover):not(#{$name}--disabled) #{$name}__input:focus-visible {
            background-color: var(--cinemo-colors-white-transparent-10);
        }
    }

    &--type-standalone:not(&--disabled) {
        @include events.hover {
            #{$name}__input {
                background-color: var(--cinemo-colors-white-transparent-10);
            }
        }

        #{$name}__input {
            @include events.hover-focus {
                background-color: var(--cinemo-colors-white-transparent-10);
            }
        }
    }

    &--disabled {
        &,
        &:hover,
        & > #{$name}__input,
        & > #{$name}__input:hover,
        & > #{$name}__label,
        & > #{$name}__label:hover {
            cursor: not-allowed;
            background-color: transparent;
        }

        & > #{$name}__input {
            &:before {
                border-color: var(--cinemo-colors-white-transparent-10);
            }

            &[data-state='checked'],
            &[data-state='indeterminate'] {
                #{$name}__input__indicator {
                    background-color: var(--cinemo-colors-white-transparent-10);
                }
            }
        }
    }

    &__input {
        $input: &;
        grid-area: input;
        position: relative;
        display: grid;
        justify-items: center;
        align-items: center;
        padding: var(--cinemo-sizes-space-xl);
        margin: calc(-1 * var(--cinemo-sizes-space-xl));
        width: 2rem;
        height: 2rem;
        box-sizing: content-box;
        background: none;
        border: none;
        border-radius: var(--cinemo-sizes-radius-full);
        cursor: pointer;

        &[data-state='unchecked']:before {
            display: block;
            position: absolute;
            content: '';
            width: 2rem;
            height: 2rem;
            border: var(--cinemo-sizes-border-s) solid;
            border-color: var(--cinemo-colors-black-600);
            border-radius: var(--cinemo-sizes-radius-full);
            box-sizing: border-box;
        }

        &[data-state='checked'],
        &[data-state='indeterminate'] {
            #{$input}__indicator {
                background-color: var(--cinemo-colors-black-100);
                color: var(--cinemo-colors-black-900);
                border-color: var(--cinemo-colors-black-100);
            }
        }

        &__indicator {
            display: grid;
            place-items: center;
            width: 2rem;
            height: 2rem;
            border-radius: var(--cinemo-sizes-radius-full);

            svg {
                width: var(--cinemo-sizes-icon-xs);
                height: var(--cinemo-sizes-icon-xs);
            }
        }
    }

    &__label {
        color: var(--cinemo-colors-black-100);
        min-width: max-content;
        cursor: pointer;

        @include typography.body-secondary;

        &--side-left {
            margin-right: var(--cinemo-sizes-space-m);
            justify-self: start;
        }

        &--side-right {
            margin-left: var(--cinemo-sizes-space-m);
            justify-self: end;
        }
    }
}
