.bif-components-transition-group {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
}

.bif-components-transition {
    position: absolute;
    inset: 0;
    overflow-y: auto;
}
