.bif-components-skeleton {
    width: 100%;
    overflow: hidden;
    position: relative;
    background: var(--cinemo-colors-black-800);
    --animation-position: 0px;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: var(--animation-position);
        width: 60vw;
        height: 100%;
        background: linear-gradient(90deg, transparent 10%, var(--cinemo-colors-black-700) 50%, transparent 90%);
        transform: translateX(-60vw);
    }

    &--service-circle {
        width: 6.4rem;
        height: 6.4rem;
        border-radius: 50%;
    }

    &--track-item {
        height: 6rem;
        border-radius: 0.4rem;
    }

    &--queue-card {
        height: 14.6rem;
        border-radius: 0.8rem;
    }

    &--playlist-card {
        height: 7.2rem;
        border-radius: 0.4rem;
        border-top-left-radius: 20rem;
        border-bottom-left-radius: 20rem;
    }

    &--device-card {
        height: 6rem;
        border-radius: 0.8rem;
    }

    &--group-card {
        height: 12.4rem;
        border-radius: 0.8rem;
    }

    &--list-item {
        height: 4.8rem;
    }
}
