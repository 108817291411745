.bif-components-table-actions {
    position: sticky;
    top: 0rem;
    height: 5.6rem;
    vertical-align: top;
    background-color: var(--cinemo-colors-black-800);
    z-index: 2;

    th {
        top: inherit;
    }

    &__content {
        height: 4rem;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
}
