@use 'typography';

.bif-components-pill {
    $root: &;
    display: inline-grid;
    grid-auto-flow: column;
    grid-column-gap: var(--cinemo-sizes-space-m);
    align-items: center;
    border-radius: 24rem;
    background-color: var(--cinemo-colors-white-transparent-10);
    box-sizing: border-box;

    &__slot {
        display: grid;

        svg {
            width: var(--cinemo-sizes-icon-xs);
            height: var(--cinemo-sizes-icon-xs);
        }
    }

    &__text {
        display: grid;
        align-items: center;
    }

    &--size-big {
        @include typography.body-primary;
        padding: var(--cinemo-sizes-space-s) var(--cinemo-sizes-space-m);
        grid-column-gap: var(--cinemo-sizes-space-xs);
        height: 3.6rem;

        #{$root}__slot {
            svg {
                width: var(--cinemo-sizes-icon-m);
                height: var(--cinemo-sizes-icon-m);
            }
        }
    }

    &--size-regular {
        @include typography.body-secondary;
        padding: var(--cinemo-sizes-space-xs) var(--cinemo-sizes-space-xxl);
        height: 2.6rem;
    }

    &--size-small {
        @include typography.caption-primary;
        padding: var(--cinemo-sizes-space-xxs) var(--cinemo-sizes-space-m);
        height: 2rem;
    }

    &--danger {
        background-color: transparent;
        border: 1px solid var(--cinemo-colors-red-500);
        color: var(--cinemo-colors-red-500);
    }
}
