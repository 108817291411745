@use 'typography';
@use 'events';

.bif-components-icon-button {
    $name: &;

    display: grid;
    position: relative;
    grid-auto-flow: column;
    justify-content: center;
    align-items: center;
    padding: var(--cinemo-sizes-space-xl);
    margin: calc(-1 * var(--cinemo-sizes-space-xl));
    border: none;
    background-color: transparent;
    color: var(--cinemo-colors-black-100);
    cursor: pointer;

    svg {
        position: relative;
        z-index: 1;
        width: var(--cinemo-sizes-icon-m);
        height: var(--cinemo-sizes-icon-m);
    }

    &:not(:disabled)::before {
        position: absolute;
        content: '';
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: var(--cinemo-sizes-radius-full);
        background-color: transparent;
        pointer-events: none;
        z-index: 0;
    }

    &--size-medium {
        &:not(:disabled)::before {
            width: var(--cinemo-sizes-cover-xs);
            height: var(--cinemo-sizes-cover-xs);
        }
    }

    &--size-small {
        &:not(:disabled)::before {
            width: 3.8rem;
            height: 3.8rem;
        }
    }

    &--size-inline {
        &:not(:disabled)::before {
            width: var(--cinemo-sizes-icon-l);
            height: var(--cinemo-sizes-icon-l);
        }
    }

    @include events.hover-focus {
        &:not(:disabled)::before {
            background-color: var(--cinemo-colors-white-transparent-10);
        }
    }

    &:focus {
        outline: none;
    }

    &--clip-interactive {
        padding: var(--cinemo-sizes-space-xl) calc(var(--cinemo-sizes-space-xl) - var(--cinemo-sizes-space-m));
        margin: calc(-1 * var(--cinemo-sizes-space-xl)) 0rem;
    }

    &--disabled {
        color: var(--cinemo-colors-black-500);
    }

    &--disabled,
    &--loading {
        @include events.hover {
            cursor: not-allowed;
        }
    }
}
