@use 'typography';
@use 'events';

@keyframes tilt-shaking {
    0% {
        transform: rotate(0deg);
    }

    25% {
        transform: rotate(3deg);
    }

    50% {
        transform: rotate(0deg);
    }

    75% {
        transform: rotate(-3deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

.bif-components-button {
    $name: &;

    display: grid;
    grid-auto-flow: column;
    grid-template-columns: auto 1fr auto;
    grid-template-rows: auto;
    grid-template-areas: 'slot-left text slot-right';
    justify-content: center;
    align-items: center;
    border: var(--cinemo-sizes-border-s) solid;
    border-radius: 4rem;

    @include typography.button;

    @include events.hover {
        cursor: pointer;
    }

    &:focus {
        outline: none;
    }

    &--animated {
        animation: tilt-shaking 0.12s 3;
    }

    &--layout-min {
        width: max-content;
    }

    &--layout-fixed {
        min-width: 16.4rem;
    }

    &--layout-max {
        width: 100%;
    }

    &--type-primary {
        border-color: var(--cinemo-colors-accent-300);
        background-color: var(--cinemo-colors-accent-300);
        color: var(--cinemo-colors-black-900);

        @include events.hover-focus {
            border-color: var(--cinemo-colors-accent-200);
            background-color: var(--cinemo-colors-accent-200);
        }

        &#{$name}--danger {
            border-color: var(--cinemo-colors-red-500);
            background-color: var(--cinemo-colors-red-500);
            color: var(--cinemo-colors-black-100);

            @include events.hover-focus {
                border-color: var(--cinemo-colors-red-700);
                background-color: var(--cinemo-colors-red-700);
            }
        }

        &#{$name}--loading,
        &#{$name}--disabled {
            border-color: var(--cinemo-colors-black-700);
            background-color: var(--cinemo-colors-black-700);
            color: var(--cinemo-colors-black-400);

            .bif-components-badge {
                color: var(--cinemo-colors-black-400);
            }

            @include events.hover {
                border-color: var(--cinemo-colors-black-700);
                background-color: var(--cinemo-colors-black-700);
                color: var(--cinemo-colors-black-400);

                .bif-components-badge {
                    color: var(--cinemo-colors-black-400);
                }
            }
        }
    }

    &--type-secondary {
        border-color: var(--cinemo-colors-black-100);
        background-color: transparent;
        color: var(--cinemo-colors-black-100);

        @include events.hover-focus {
            border-color: var(--cinemo-colors-black-300);
            color: var(--cinemo-colors-black-300);
        }

        &#{$name}--danger {
            border-color: var(--cinemo-colors-red-500);
            color: var(--cinemo-colors-red-500);

            .bif-components-badge {
                color: var(--cinemo-colors-red-500);
            }

            @include events.hover-focus {
                border-color: var(--cinemo-colors-red-700);
                color: var(--cinemo-colors-red-700);

                .bif-components-badge {
                    color: var(--cinemo-colors-red-700);
                }
            }
        }

        &#{$name}--loading,
        &#{$name}--disabled {
            border-color: var(--cinemo-colors-black-500);
            color: var(--cinemo-colors-black-500);

            .bif-components-badge {
                color: var(--cinemo-colors-black-500);
            }

            @include events.hover {
                border-color: var(--cinemo-colors-black-500);
                color: var(--cinemo-colors-black-500);

                .bif-components-badge {
                    color: var(--cinemo-colors-black-500);
                }
            }
        }
    }

    &--type-tertiary,
    &--type-tertiary--accent {
        border-color: transparent;
        background-color: transparent;

        &#{$name}--danger {
            color: var(--cinemo-colors-red-500);

            .bif-components-badge {
                color: var(--cinemo-colors-red-500);
            }

            @include events.hover-focus {
                color: var(--cinemo-colors-red-700);

                .bif-components-badge {
                    color: var(--cinemo-colors-red-700);
                }
            }
        }

        &#{$name}--loading,
        &#{$name}--disabled {
            color: var(--cinemo-colors-black-500);

            .bif-components-badge {
                color: var(--cinemo-colors-black-500);
            }

            @include events.hover-focus {
                color: var(--cinemo-colors-black-500);

                .bif-components-badge {
                    color: var(--cinemo-colors-black-500);
                }
            }
        }
    }

    &--type-tertiary {
        color: var(--cinemo-colors-black-100);

        @include events.hover-focus {
            color: var(--cinemo-colors-black-300);
        }
    }

    &--type-tertiary--accent {
        color: var(--cinemo-colors-accent-300);

        @include events.hover-focus {
            color: var(--cinemo-colors-accent-200);
        }
    }

    &--size-inline {
        padding: 0rem;
        border: none;

        #{$name}__slot {
            &--left {
                margin-right: var(--cinemo-sizes-space-xxl);
            }

            &--right {
                margin-left: var(--cinemo-sizes-space-s);
            }

            &:only-child {
                margin: 0rem;
            }
        }
    }

    &--size-small {
        padding: var(--cinemo-sizes-space-m) var(--cinemo-sizes-space-xxl);

        #{$name}__slot {
            &--left {
                margin-right: var(--cinemo-sizes-space-m);
            }

            &--right {
                margin-left: var(--cinemo-sizes-space-m);
            }

            &:only-child {
                margin: 0 -0.8rem;
            }
        }
    }

    &--size-medium {
        padding: var(--cinemo-sizes-space-xl) var(--cinemo-sizes-space-xxl);

        #{$name}__slot {
            &--left {
                margin-right: var(--cinemo-sizes-space-l);
            }

            &--right {
                margin-left: var(--cinemo-sizes-space-l);
            }

            &:only-child {
                margin: 0 -0.4rem;
            }
        }
    }

    &--loading {
        grid-template-columns: 1fr;
        grid-template-areas: none;
    }

    &--loading,
    &--disabled {
        @include events.hover {
            cursor: not-allowed;
        }
    }

    &__slot {
        display: grid;
        justify-items: center;
        align-items: center;
        max-width: fit-content;

        svg {
            width: var(--cinemo-sizes-icon-s);
            height: var(--cinemo-sizes-icon-s);
        }

        .bif-components-badge {
            margin: -0.5rem;
        }

        &--left {
            grid-area: slot-left;
        }

        &--right {
            grid-area: slot-right;
        }
    }

    &__text {
        grid-area: text;
    }
}
