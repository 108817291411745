.bif-components-table-row {
    background: var(--cinemo-colors-white-transparent-05);
    height: 7rem;

    td {
        padding: 0 var(--cinemo-sizes-keyline-s);
        overflow: hidden;
    }

    td:first-child {
        border-top-left-radius: var(--cinemo-sizes-radius-m);
        border-bottom-left-radius: var(--cinemo-sizes-radius-m);
    }

    td:last-child {
        border-top-right-radius: var(--cinemo-sizes-radius-m);
        border-bottom-right-radius: var(--cinemo-sizes-radius-m);
    }

    &__separator {
        height: var(--cinemo-sizes-gap-xxs);
    }

    &--checked {
        background: var(--cinemo-colors-white-transparent-20);
    }
}
