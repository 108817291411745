@use 'devices';

.bif-components-menu__content {
    &-exit {
        opacity: 1;
        transform: scale(1);
    }

    &-exit-active {
        opacity: 0;
        transition-timing-function: ease-out;
        transform: scale(0.75);
        transition:
            opacity 100ms,
            transform 100ms;
        transform-origin: var(--radix-dropdown-menu-content-transform-origin);
    }

    &-enter {
        opacity: 0;
        transform: scale(0.75);
    }

    &-enter-active {
        opacity: 1;
        transition-timing-function: ease-out;
        transform: scale(1);
        transition:
            opacity 200ms,
            transform 200ms;
        transform-origin: var(--radix-dropdown-menu-content-transform-origin);
    }
}

.bif-components-menu__content-mobile-fullscreen {
    &-exit {
        opacity: 1;
        transform: scale(1);

        @include devices.phone {
            transform: translateY(0);
        }
    }

    &-exit-active {
        opacity: 0;
        transition-timing-function: ease-out;
        transform: scale(0.75);
        transition:
            opacity 100ms,
            transform 100ms;

        @include devices.tablet-portrait-up {
            transform-origin: var(--radix-dropdown-menu-content-transform-origin);
        }

        @include devices.phone {
            transform: translateY(100%);
            transition:
                opacity 200ms,
                transform 200ms;
        }
    }

    &-enter {
        opacity: 0;
        transform: scale(0.75);

        @include devices.phone {
            transform: translateY(100%);
        }
    }

    &-enter-active {
        opacity: 1;
        transition-timing-function: ease-out;
        transform: scale(1);
        transition:
            opacity 200ms,
            transform 200ms;

        @include devices.tablet-portrait-up {
            transform-origin: var(--radix-dropdown-menu-content-transform-origin);
        }

        @include devices.phone {
            transform: translateY(0);
            transition:
                opacity 250ms,
                transform 250ms;
        }
    }
}
