@use 'typography';

.bif-components-tabs {
    border-radius: var(--cinemo-sizes-radius-xxl);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: var(--cinemo-sizes-space-s);
    width: 100%;

    &--framed {
        background-color: var(--cinemo-colors-black-900);
        padding: var(--cinemo-sizes-space-xs);
        gap: var(--cinemo-sizes-space-m);
        width: max-content;
    }

    &--framed.bif-components-tabs--disabled {
        background-color: var(--cinemo-colors-black-800);
    }
}
