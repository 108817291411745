@use 'typography';

.bif-components-field-edit-form {
    display: grid;
    grid-gap: var(--cinemo-sizes-space-m);
    border-radius: var(--cinemo-sizes-radius-m);
    background: var(--cinemo-colors-white-transparent-10);
    padding: var(--cinemo-sizes-space-s);
    box-sizing: border-box;

    .bif-components-text-input {
        gap: 0;
    }

    &__actions {
        display: flex;
        justify-content: space-between;
        padding-inline: var(--cinemo-sizes-space-m);
    }

    button {
        @include typography.button;
    }

    .bif-components-text-input__validation {
        margin-bottom: 0;
    }
}
