@mixin hover {
    @media (hover: hover) {
        &:hover {
            @content;
        }
    }
}

@mixin hover-focus {
    @include hover {
        @content;
    }

    &:focus-visible {
        @content;
    }
}
