.bif-templates-content-list {
    display: grid;
    padding: var(--cinemo-sizes-gap-s) var(--cinemo-sizes-keyline-m);
    gap: var(--cinemo-sizes-gap-l);

    &__cover-container {
        $cover-container: &;
        display: grid;

        &--playlist {
            gap: var(--cinemo-sizes-gap-xxs);

            #{$cover-container}__cover-description {
                display: grid;
                gap: var(--cinemo-sizes-gap-xxs);
            }
        }

        &--artist,
        &--album {
            gap: var(--cinemo-sizes-gap-s);
        }
    }

    &__content {
        display: grid;
    }
}
