@use 'typography';
@use 'events';

.bif-components-toggle {
    $name: &;

    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;
    align-items: center;

    &--label-left {
        grid-template-areas: 'label input';
    }

    &--label-right {
        grid-template-areas: 'input label';
    }

    &--spacing-regular {
        margin-bottom: var(--cinemo-sizes-space-xxxl);
    }

    &--layout-min {
        max-width: min-content;
    }

    &--layout-max {
        width: 100%;
    }

    &--disabled {
        &,
        &:hover,
        & > #{$name}__input,
        & > #{$name}__input:hover {
            cursor: not-allowed;
        }

        & > #{$name}__input {
            border-color: var(--cinemo-colors-black-600);

            &[data-state='unchecked'] {
                & > #{$name}__input__indicator {
                    background-color: var(--cinemo-colors-black-600);
                }
            }

            &[data-state='checked'],
            &[data-state='indeterminate'] {
                background-color: var(--cinemo-colors-black-600);

                & > #{$name}__input__indicator {
                    background-color: var(--cinemo-colors-black-400);
                }
            }
        }
    }

    &__input {
        grid-area: input;
        display: grid;
        justify-items: center;
        align-items: center;
        width: 4.6rem;
        height: 2.6rem;
        padding: 0rem var(--cinemo-sizes-radius-xxs);
        border: var(--cinemo-sizes-border-s) solid;
        border-radius: var(--cinemo-sizes-radius-xxl);
        border-color: var(--cinemo-colors-black-100);
        cursor: pointer;
        transition: all 0.1s ease-out;

        &[data-state='unchecked'] {
            background-color: transparent;

            & > #{$name}__input__indicator {
                background-color: var(--cinemo-colors-black-100);
                transform: translateX(-1rem);
            }

            &:not(:disabled) {
                @include events.hover-focus {
                    background-color: var(--cinemo-colors-black-transparent-40);
                }
            }
        }

        &[data-state='checked'],
        &[data-state='indeterminate'] {
            background-color: var(--cinemo-colors-black-100);

            & > #{$name}__input__indicator {
                background-color: var(--cinemo-colors-black-800);
                transform: translateX(1rem);
            }

            &:not(:disabled) {
                @include events.hover-focus {
                    background-color: var(--cinemo-colors-black-150);
                    border-color: var(--cinemo-colors-black-150);

                    & > #{$name}__input__indicator {
                        background-color: var(--cinemo-colors-black-900);
                    }
                }
            }
        }

        &__indicator {
            width: 2rem;
            height: 2rem;
            border-radius: var(--cinemo-sizes-radius-full);
            transition: all 0.2s ease-in-out;
        }
    }

    &__label {
        color: var(--cinemo-colors-black-100);
        min-width: max-content;

        @include typography.body-secondary;

        &--side-left {
            margin-right: var(--cinemo-sizes-space-m);
            justify-self: start;
        }

        &--side-right {
            margin-left: var(--cinemo-sizes-space-m);
            justify-self: end;
        }
    }
}
