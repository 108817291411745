@use 'events';

.bif-components-table-sort-label {
    display: flex;
    align-items: center;
    gap: 0.8rem;

    &--disabled {
        pointer-events: none;
    }

    .bif-components-icon-button {
        svg {
            opacity: 0;
        }
    }

    &--active {
        .bif-components-icon-button {
            svg {
                opacity: 1;
            }
        }
    }

    @include events.hover-focus {
        cursor: pointer;

        .bif-components-icon-button {
            color: var(--cinemo-colors-white-transparent-50);

            svg {
                opacity: 1;
            }

            &::before {
                background: var(--cinemo-colors-white-transparent-10);
            }
        }
    }
}
