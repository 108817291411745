@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.bif-components-spinner {
    display: grid;
    justify-items: center;
    align-items: center;
    width: inherit;
    height: inherit;
    color: var(--cinemo-colors-white-transparent-70);

    svg {
        animation: rotate 0.5s linear infinite;
    }

    &--size-small {
        svg {
            width: var(--cinemo-sizes-icon-s);
            height: var(--cinemo-sizes-icon-s);
        }
    }

    &--size-large {
        svg {
            width: var(--cinemo-sizes-icon-xl);
            height: var(--cinemo-sizes-icon-xl);
        }
    }
}
