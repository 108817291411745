@use 'typography';
@use 'events';

.bif-components-alert {
    $name: &;

    display: grid;
    grid-auto-flow: row;
    grid-template-columns: 1fr;
    row-gap: var(--cinemo-sizes-space-xl);
    padding: calc(var(--cinemo-sizes-space-m) - var(--cinemo-sizes-border-s))
        calc(var(--cinemo-sizes-space-xxl) - var(--cinemo-sizes-border-s));
    border: var(--cinemo-sizes-border-s) solid;
    border-radius: var(--cinemo-sizes-radius-s);

    @include typography.caption-primary;

    &--layout-fixed {
        width: 40rem;
    }

    &--severity-info {
        &#{$name}--type-primary {
            border-color: var(--cinemo-colors-black-700);
            background-color: var(--cinemo-colors-black-700);
            color: var(--cinemo-colors-black-100);
        }

        &#{$name}--type-secondary {
            border-color: var(--cinemo-colors-black-100);
            background-color: var(--cinemo-colors-white-transparent-05);
            color: var(--cinemo-colors-black-100);

            #{$name}__action {
                color: var(--cinemo-colors-black-100);
            }
        }
    }

    &--severity-success {
        &#{$name}--type-primary {
            border-color: var(--cinemo-colors-green-500);
            background-color: var(--cinemo-colors-green-500);
            color: var(--cinemo-colors-black-100);
        }

        &#{$name}--type-secondary {
            border-color: var(--cinemo-colors-green-500);
            color: var(--cinemo-colors-green-500);
        }
    }

    &--severity-warning {
        &#{$name}--type-primary {
            border-color: var(--cinemo-colors-yellow-500);
            background-color: var(--cinemo-colors-yellow-500);
            color: var(--cinemo-colors-black-900);
        }

        &#{$name}--type-secondary {
            border-color: var(--cinemo-colors-yellow-500);
            color: var(--cinemo-colors-yellow-500);
        }
    }

    &--severity-error {
        &#{$name}--type-primary {
            border-color: var(--cinemo-colors-red-500);
            background-color: var(--cinemo-colors-red-500);
            color: var(--cinemo-colors-black-100);
        }

        &#{$name}--type-secondary {
            border-color: var(--cinemo-colors-red-500);
            color: var(--cinemo-colors-red-500);
        }
    }

    &#{$name}--type-secondary #{$name}__action {
        color: var(--cinemo-colors-black-100);
    }

    &__message {
        display: grid;
        width: 100%;
        grid-auto-flow: column;
        grid-template-columns: auto 1fr auto;
        grid-template-areas: 'slot text action';
        align-items: center;
    }

    &__slot {
        grid-area: slot;
        display: grid;
        margin-right: var(--cinemo-sizes-space-xl);

        svg {
            width: var(--cinemo-sizes-icon-m);
            height: var(--cinemo-sizes-icon-m);
            margin: -0.1rem;
        }
    }

    &__text {
        grid-area: text;
        display: grid;
        align-items: center;
        min-height: 3.2rem;
    }

    &__action {
        grid-area: action;
        margin-left: var(--cinemo-sizes-space-xl);
        padding: var(--cinemo-sizes-space-xs) var(--cinemo-sizes-space-m);
        border-radius: var(--cinemo-sizes-radius-m);
        background: none;
        border: none;
        color: inherit;

        @include typography.button;

        &:focus {
            outline: none;
        }

        @include events.hover {
            cursor: pointer;
        }

        @include events.hover-focus {
            background-color: var(--cinemo-colors-white-transparent-20);
            color: inherit;
        }
    }
}
