.bif-components-country-input {
    .bif-components-scrollable-area__viewport {
        div:first-child {
            width: 100%;
            table-layout: fixed;
        }
    }

    .bif-components-combobox__items__item__label {
        width: auto;
        padding-right: 0;
        white-space: normal;
    }
}
