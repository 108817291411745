.bif-components-stepper {
    display: grid;
    grid-auto-flow: column;
    width: fit-content;

    &__step {
        align-items: center;
        display: flex;

        &__circle {
            width: 1.2rem;
            height: 1.2rem;
            display: inline-grid;
            border-radius: var(--cinemo-sizes-radius-full);

            &--clickable {
                cursor: pointer;
            }

            &--disabled {
                background-color: var(--cinemo-colors-white-transparent-20);
            }

            &--completed {
                background-color: var(--cinemo-colors-black-100);
                justify-items: center;
                align-items: center;

                svg {
                    color: var(--cinemo-colors-black-900);
                    width: 0.8rem;
                    height: 0.8rem;
                }
            }

            &--current {
                svg {
                    width: 1.2rem;
                    height: 1.2rem;
                }
            }
        }

        &__connector {
            border: var(--cinemo-sizes-border-s) solid var(--cinemo-colors-white-transparent-20);
            border-radius: var(--cinemo-sizes-radius-xs);
            margin: 0 var(--cinemo-sizes-space-xs);
            width: 4rem;
        }
    }
}
