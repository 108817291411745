@use 'typography';
@use 'events';

.bif-components-tab {
    $base: &;
    position: relative;

    &__checkbox {
        // Hack to leave the checkbox hidden but still accessible
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;

        &:disabled {
            cursor: unset;
        }

        @include events.hover-focus {
            &:not([disabled]) + #{$base}__label {
                &--white {
                    background-color: var(--cinemo-colors-white-transparent-10);
                }

                &--grey {
                    background-color: var(--cinemo-colors-white-transparent-30);
                }
            }
        }
    }

    &__checkbox:checked:not([disabled]) + &__label {
        &--white {
            background-color: var(--cinemo-colors-black-100);
            color: var(--cinemo-colors-black-900);
        }

        &--grey {
            background-color: var(--cinemo-colors-white-transparent-20);
            color: var(--cinemo-colors-black-100);
        }
    }

    &__checkbox:checked:not([disabled]) + &__label &__label__badge {
        background-color: var(--cinemo-colors-black-900);
        color: var(--cinemo-colors-black-100);
    }

    &__label {
        display: flex;
        align-items: center;
        gap: var(--cinemo-sizes-space-xs);
        padding: var(--cinemo-sizes-space-m) var(--cinemo-sizes-space-xl);
        border-radius: 20rem;
        background-color: var(--cinemo-colors-black-transparent-30);
        color: var(--cinemo-colors-black-100);
        cursor: pointer;
        user-select: none;

        @include typography.caption-primary;

        &--disabled {
            background-color: unset;
            color: var(--cinemo-colors-white-transparent-30);

            #{$base}__label__badge {
                background-color: unset;
            }
        }

        &--with-badge {
            padding: var(--cinemo-sizes-space-m) var(--cinemo-sizes-space-l);
        }

        &--with-indicator {
            &::after {
                content: '';
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: var(--cinemo-colors-accent-300);
            }
        }

        &__badge {
            background-color: var(--cinemo-colors-white-transparent-20);
        }

        svg {
            width: var(--cinemo-sizes-icon-xs);
            height: var(--cinemo-sizes-icon-xs);
        }
    }
}
