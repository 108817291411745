.bif-components-date-picker-years {
    height: 20.8rem;
    width: 100%;
    margin-bottom: var(--cinemo-sizes-space-xxl);

    .bif-components-scrollable-area {
        padding: 0 var(--cinemo-sizes-space-xxl);
        box-sizing: border-box;
    }

    .bif-components-scrollable-area__viewport > div {
        display: grid !important;
        grid-template-columns: repeat(3, 1fr);
        grid-auto-rows: 5.2rem;
    }
}
