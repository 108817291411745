@use 'devices';

.bif-templates-generic {
    display: grid;
    padding: var(--cinemo-sizes-gap-s) var(--cinemo-sizes-keyline-m);
    margin: auto;
    box-sizing: border-box;

    @include devices.tablet-portrait-up {
        max-width: calc(var(--cinemo-sizes-container-width-m) + (2 * var(--cinemo-sizes-keyline-m)));
    }
}
