@use 'typography';

.bif-components-validation {
    display: grid;
    grid-auto-flow: row;
    row-gap: var(--cinemo-sizes-space-s);
    min-height: 1.6rem;
    margin-top: var(--cinemo-sizes-space-xxs);
    margin-bottom: var(--cinemo-sizes-space-l);
    padding-left: var(--cinemo-sizes-space-xl);

    &__message {
        @include typography.caption-primary;
        display: flex;
        align-items: center;
        gap: var(--cinemo-sizes-space-xxs);

        &--info {
            color: var(--cinemo-colors-white-transparent-60);
        }

        &--success {
            color: var(--cinemo-colors-black-100);

            svg {
                width: var(--cinemo-sizes-icon-xxs);
                height: var(--cinemo-sizes-icon-xxs);
            }
        }

        &--warning {
            color: var(--cinemo-colors-yellow-500);
        }

        &--error {
            color: var(--cinemo-colors-red-400);
        }
    }
}
