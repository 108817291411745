@use 'typography';

.bif-components-table-scroll {
    overflow-y: auto;
    padding-right: 1rem;
    margin-right: -1rem;
    height: inherit;
    max-height: inherit;
}

@mixin first_cell {
    border-top-left-radius: var(--cinemo-sizes-radius-m);
    border-bottom-left-radius: var(--cinemo-sizes-radius-m);
}

@mixin last_cell {
    border-top-right-radius: var(--cinemo-sizes-radius-m);
    border-bottom-right-radius: var(--cinemo-sizes-radius-m);
}

.bif-components-table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;

    &__head {
        &__row {
            position: sticky;
            top: 0rem;
            height: 3.4rem;
            vertical-align: middle;
            background-color: var(--cinemo-colors-black-900);
            z-index: 2;

            &--with-actions {
                top: 5.6rem;
            }

            &--with-filters {
                top: 9.8rem;
            }

            &--no-rows {
                display: none;
            }

            th {
                top: inherit;
                height: 3.8rem;
                box-sizing: border-box;
                text-align: left;
                user-select: none;
                padding: var(--cinemo-sizes-space-xxs) var(--cinemo-sizes-keyline-s);
            }

            &__sort-icon {
                margin-left: var(--cinemo-sizes-gap-s);
                cursor: pointer;
            }

            th:first-child {
                @include first_cell;
            }

            th:last-child {
                @include last_cell();
            }
        }
    }

    &__body {
        &__loader {
            margin: 5.2rem 0 4.8rem;
        }

        &__nothing-found {
            text-align: center;
            margin: 6.4rem 0 6rem;
        }
    }
}
