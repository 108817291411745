.bif-components-table-filters {
    position: sticky;
    top: 5.6rem;
    height: 4.2rem;
    vertical-align: top;
    background-color: var(--cinemo-colors-black-800);
    z-index: 2;

    th {
        top: inherit;
    }

    &__content {
        height: 2.6rem;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
    }
}
