@use 'typography';
@use 'devices';

.bif-shell-error {
    position: fixed;
    inset: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--cinemo-colors-black-900);
    padding: 0 var(--cinemo-sizes-gap-l);
    z-index: 10;

    &__title {
        margin-top: calc(8rem + env(safe-area-inset-top));
        margin-bottom: var(--cinemo-sizes-gap-s);
        @include typography.h3;
        color: var(--cinemo-colors-black-100);
        text-align: center;
    }

    &__description {
        @include typography.body-primary;
        color: var(--cinemo-colors-white-transparent-70);
        text-align: center;
    }

    &__icon {
        margin-top: auto;
        width: auto;
        height: auto;
    }

    &__back {
        margin-top: auto;
        margin-bottom: calc(var(--cinemo-sizes-gap-s) + env(safe-area-inset-bottom));
    }

    @include devices.desktop {
        &__title {
            margin-top: auto;
        }

        &__icon {
            margin-top: 3.2rem;
        }

        &__back {
            margin-top: 3.2rem;
            margin-bottom: auto;
        }
    }
}
