.bif-shared-vlist {
    overflow: auto;

    &::-webkit-scrollbar {
        width: var(--cinemo-sizes-space-xxxs);
        height: var(--cinemo-sizes-space-xxxs);
    }

    &::-webkit-scrollbar-track {
        background-color: var(--cinemo-colors-black-transparent-50);
    }

    &::-webkit-scrollbar-thumb {
        background-color: var(--cinemo-colors-white-transparent-50);
        border-radius: var(--cinemo-sizes-space-xxxs);
    }

    &--window {
        &::-webkit-scrollbar {
            display: none;
        }
    }

    &__list {
        position: relative;

        &__item {
            position: absolute;
            top: 0;
            left: 0;
        }
    }
}
