.bif-templates-main-page {
    display: grid;
    padding: var(--cinemo-sizes-gap-s) var(--cinemo-sizes-keyline-xs) 0;
    gap: var(--cinemo-sizes-gap-m);

    &__horizontal-list {
        display: grid;
        position: relative;
        justify-content: center;

        &__list {
            position: relative;
            width: 100%;
            display: flex;
            align-items: flex-start;
            gap: var(--cinemo-sizes-keyline-m);
            overflow-x: auto;

            &::-webkit-scrollbar {
                display: none;
            }

            // adding gaps before 1st component and after last component
            &::after,
            &::before {
                content: '';
            }
        }
    }

    &__sections {
        display: grid;
        gap: var(--cinemo-sizes-gap-xxs);

        &__section {
            display: grid;
            padding: var(--cinemo-sizes-gap-s) var(--cinemo-sizes-keyline-m);
            background-color: var(--cinemo-colors-black-850);
            gap: var(--cinemo-sizes-gap-s);
            border-radius: var(--cinemo-sizes-radius-xxl);
        }
    }
}
