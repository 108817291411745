@use 'devices';

.bif-templates-page-wrapper {
    display: grid;
    grid-template-rows: max-content 1fr;

    &__header {
        position: sticky;
        top: 0;
        z-index: 1;

        @include devices.phone {
            &--static {
                position: static;
            }
        }
    }

    &__header,
    &__content {
        @include devices.desktop {
            width: 100%;
            max-width: 114.4rem;
            margin: 0 auto;
        }
    }
}
