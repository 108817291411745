@use 'typography';
@use 'devices';

@import '@bifrost/web-components/dist/index.css';
@import '@bifrost/web-templates/dist/index.css';

html,
body {
    height: 100%;
}

.bif-shell {
    display: grid;
    height: 100%;
    background-color: var(--cinemo-colors-black-900);
    color: var(--cinemo-colors-black-100);

    @include devices.tablet-landscape-down {
        grid-auto-flow: row;
        grid-template-rows: 1fr max-content;
        grid-template-areas: 'slot' 'shared';
    }

    @include devices.desktop {
        grid-auto-flow: column;
        grid-template-columns: max-content 1fr;
        grid-template-areas: 'shared slot';
    }

    @include typography.body-primary;

    &__shared {
        grid-area: shared;
    }

    &__slot {
        grid-area: slot;
        position: relative;
        overflow-y: auto;
    }
}
