@use 'typography';
@use 'events';

.bif-components-text-input {
    $name: &;

    display: grid;
    grid-auto-flow: row;
    grid-template-areas:
        'label'
        'field'
        'validation';

    grid-gap: var(--cinemo-sizes-space-s);

    svg {
        width: var(--cinemo-sizes-icon-s);
        height: var(--cinemo-sizes-icon-s);
    }

    &--spacing-regular {
        & #{$name}__field:last-child {
            margin-bottom: var(--cinemo-sizes-space-xxxl);
        }
    }

    &--layout-fixed {
        width: 34rem;
    }

    &--layout-max {
        width: 100%;
    }

    &__label {
        grid-area: label;
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: min-content;
        column-gap: var(--cinemo-sizes-space-xxxs);
        margin-bottom: var(--cinemo-sizes-space-xxs);
        color: var(--cinemo-colors-black-300);

        @include typography.body-secondary;

        &__text {
            width: max-content;
        }

        &__content {
            display: inline-grid;
            align-items: center;

            svg {
                width: var(--cinemo-sizes-icon-xs);
                height: var(--cinemo-sizes-icon-xs);
            }
        }
    }

    &--theme-dark {
        #{$name}__field {
            border-color: var(--cinemo-colors-black-900);
            background-color: var(--cinemo-colors-black-900);

            &:focus-within {
                background-color: var(--cinemo-colors-black-900);
                border-color: var(--cinemo-colors-black-700);

                & > #{$name}__field__input {
                    background-color: var(--cinemo-colors-black-900);
                    border-color: var(--cinemo-colors-black-700);
                }
            }

            &__input {
                background-color: var(--cinemo-colors-black-900);
            }

            &--empty {
                &,
                & > #{$name}__field__input {
                    background-color: var(--cinemo-colors-black-850);
                    border-color: var(--cinemo-colors-black-850);
                }

                &#{$name}__field--rounded {
                    #{$name}__field__input {
                        &::placeholder {
                            color: var(--cinemo-colors-white-transparent-50);
                        }
                        &:focus-visible {
                            outline: none;
                            &::placeholder {
                                color: transparent;
                            }
                        }
                    }
                }
            }
        }
    }

    &--theme-light {
        #{$name}__field {
            border-color: var(--cinemo-colors-black-800);
            background-color: var(--cinemo-colors-black-800);

            &__input {
                background-color: var(--cinemo-colors-black-800);
            }

            &--empty {
                &,
                & > #{$name}__field__input {
                    background-color: var(--cinemo-colors-black-800);
                }

                &#{$name}__field--rounded {
                    &,
                    & > #{$name}__field__input {
                        background-color: var(--cinemo-colors-black-700);
                    }
                }
            }

            &:focus-within {
                &,
                & > #{$name}__field__input {
                    border-color: var(--cinemo-colors-black-700);
                    background-color: var(--cinemo-colors-black-800);
                }
            }
        }
    }

    &__field {
        grid-area: field;
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: auto 1fr auto;
        grid-template-rows: auto;
        grid-template-areas: 'slot-left input clear reveal slot-right';
        align-items: center;
        padding: 0;
        border: var(--cinemo-sizes-border-s) solid;
        border-radius: var(--cinemo-sizes-radius-m);
        color: var(--cinemo-colors-black-100);
        min-height: 4rem;
        box-sizing: border-box;

        &:not(&--disabled) {
            @include events.hover-focus {
                border-color: var(--cinemo-colors-black-700);
            }
        }

        &#{$name}__field--disabled {
            cursor: not-allowed;
            color: var(--cinemo-colors-black-600);
            background-color: var(--cinemo-colors-black-850);
            & > #{$name}__field__input {
                background-color: var(--cinemo-colors-black-850);
            }
        }

        &#{$name}__field--error {
            border-color: var(--cinemo-colors-red-400);
        }

        &--rounded {
            &,
            #{$name}__field__input {
                // TODO: Replace hardcoded value with variable name once its implemented
                border-radius: 200px;
            }

            #{$name}__field {
                &__slot--side-left,
                &__slot--side-right {
                    margin: 0 var(--cinemo-sizes-space-m);
                }

                &__input {
                    padding: var(--cinemo-sizes-space-m) var(--cinemo-sizes-space-xxl);
                    @include typography.body-secondary;
                }
            }
        }

        &,
        &--rounded {
            #{$name}__field__input {
                &--clip-left {
                    padding-left: 0;
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                }

                &--clip-right {
                    padding-right: 0;
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }
            }
        }

        &__slot {
            display: grid;
            justify-items: center;
            align-items: center;
            max-width: fit-content;

            &--side-left {
                grid-area: slot-left;
            }

            &--side-right {
                grid-area: slot-right;
            }

            &--side-left,
            &--side-right {
                margin: 0 var(--cinemo-sizes-space-xl);
            }
        }

        &__input {
            grid-area: input;
            width: 100%;
            box-sizing: border-box;
            padding: var(--cinemo-sizes-space-xl);
            border: none;
            border-radius: var(--cinemo-sizes-radius-m);
            color: var(--cinemo-colors-black-100);

            @include typography.body-primary;

            &::placeholder {
                color: var(--cinemo-colors-white-transparent-60);
            }

            &::-moz-placeholder {
                opacity: 1;
            }

            &:focus-visible {
                outline: none;
                &::placeholder {
                    color: transparent;
                }
            }

            &--disabled {
                cursor: not-allowed;
                color: var(--cinemo-colors-black-600);

                &::placeholder {
                    color: var(--cinemo-colors-black-600);
                }
            }
        }

        &__clear {
            grid-area: clear;
        }

        &__reveal {
            grid-area: reveal;
        }

        &__clear,
        &__reveal {
            display: grid;
            place-items: center;
            height: 100%;
            border: none;
            margin-left: var(--cinemo-sizes-space-m);
            padding: 0 var(--cinemo-sizes-space-xl) 0;
            background: none;
            color: inherit;
            cursor: pointer;

            &--clip-right {
                padding-right: var(--cinemo-sizes-space-xs);
            }

            &--clip-left {
                padding-left: var(--cinemo-sizes-space-xs);
            }
        }
    }

    &__validation {
        grid-area: validation;

        svg {
            width: var(--cinemo-sizes-icon-xxs);
            height: var(--cinemo-sizes-icon-xxs);
        }
    }
}
