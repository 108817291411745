@use 'typography';

.bif-components-autocomplete-input {
    $name: &;

    display: grid;
    grid-auto-flow: row;
    gap: var(--cinemo-sizes-space-l);

    &--layout-fixed {
        width: 34rem;
    }

    &--layout-max {
        width: 100%;
    }

    &--theme-light {
        #{$name}__items {
            background-color: var(--cinemo-colors-black-800);
        }
    }

    &--theme-dark {
        #{$name}__items {
            background-color: var(--cinemo-colors-black-900);
        }
    }

    &__input {
        padding: var(--cinemo-sizes-space-xs) 0;
        border: none;
        color: var(--cinemo-colors-black-100);
        background-color: transparent;
        min-width: 9rem;

        @include typography.body-secondary;

        &::placeholder {
            color: var(--cinemo-colors-white-transparent-60);
        }

        &::-moz-placeholder {
            opacity: 1;
        }

        &:focus-visible {
            outline: none;
            &::placeholder {
                color: transparent;
            }
        }

        &:placeholder-shown {
            text-overflow: ellipsis;
        }

        &--disabled {
            cursor: not-allowed;
            color: var(--cinemo-colors-black-600);

            &::placeholder {
                color: var(--cinemo-colors-black-600);
            }
        }
    }

    &__items {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: var(--cinemo-sizes-space-xs);
        border-radius: var(--cinemo-sizes-radius-m);
        padding: var(--cinemo-sizes-space-xl);
        border: var(--cinemo-sizes-border-s) solid var(--cinemo-colors-black-700);
    }

    &__item {
        width: fit-content;
        box-sizing: border-box;
        padding: var(--cinemo-sizes-space-xs) var(--cinemo-sizes-space-m);
        border: var(--cinemo-sizes-border-s) solid transparent;

        &__text {
            @include typography.body-secondary;
        }

        &__button {
            display: flex;
            background: transparent;
            border: none;
            color: var(--cinemo-colors-black-100);
            padding: 0;
            cursor: pointer;

            svg {
                width: var(--cinemo-sizes-icon-xs);
                height: var(--cinemo-sizes-icon-xs);
            }
        }

        &--error {
            border: var(--cinemo-sizes-border-s) solid var(--cinemo-colors-red-500);

            #{$name}__item__button {
                color: var(--cinemo-colors-red-500);
            }
        }
    }

    &__label {
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: min-content;
        column-gap: var(--cinemo-sizes-space-xxxs);
        margin-bottom: var(--cinemo-sizes-space-xxs);
        color: var(--cinemo-colors-black-300);

        @include typography.body-secondary;

        &__text {
            width: max-content;
        }

        &__content {
            display: inline-grid;
            align-items: center;

            svg {
                width: var(--cinemo-sizes-icon-xs);
                height: var(--cinemo-sizes-icon-xs);
            }
        }
    }
}
