@use 'typography';
@use 'events';

.bif-components-date-picker-cell {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    background: none;
    border: none;
    border-radius: 0.8rem;
    cursor: pointer;
    @include typography.body-primary;
    color: var(--cinemo-colors-black-300);
    outline: none;

    @include events.hover-focus {
        background-color: var(--cinemo-colors-white-transparent-10);
    }

    &--today {
        @include typography.h5;
        color: var(--cinemo-colors-black-100);
    }

    &--selected {
        background-color: var(--cinemo-colors-black-100);
        @include typography.h5;
        color: var(--cinemo-colors-black-900);

        @include events.hover-focus {
            background-color: var(--cinemo-colors-black-100);
        }
    }

    &:disabled {
        color: var(--cinemo-colors-black-600);
        pointer-events: none;
    }
}
